<template>
  <content-wrapper class="city">
    <!--    <div class="articles">-->
    <!--      <h1>О городе</h1>-->
    <!--      <swiper :options="{slidesPerView: 'auto', touchRatio: 2}">-->
    <!--        <swiper-slide v-for="(fact, index) in [...facts]" :key="index">-->
    <!--          <router-link :to="`/fact/${fact.id}`">-->
    <!--            <fact-->
    <!--              :img="fact.images[Object.keys(fact.images)[0]]"-->
    <!--              :title="fact.title"-->
    <!--            />-->
    <!--          </router-link>-->
    <!--        </swiper-slide>-->
    <!--      </swiper>-->
    <!--    </div>-->

    <loader v-if="loadingStatus" />

    <div v-if="places && places.length" class="grid">
      <div v-for="(object, index) in places" :key="index" class="event">
        <object-card
            type="icon"
            width="488px"
            height="275px"
            :img="object.images[Object.keys(object.images)[0]].replace('extra','medium')"
            :title="object.title"
            :category="object.type.name"
            :icon="object.type.image"
            @click.native="goToObject(object)"
        />
      </div>
    </div>

    <div v-else class="events-plug">
      Ничего не найдено
    </div>

    <div class="tags swiper-width">
      <swiper
          :options="{ slidesPerView: 'auto', touchRatio: 2 }"
      >
        <swiper-slide>
          <tag
              title="Все"
              :active="activeBadge === 0"
              @click.native="changeBadge(0)"
          />
        </swiper-slide>
        <swiper-slide
            v-for="(tag, index) in types"
            :key="index"
        >
          <tag
              :title="tag.title"
              :active="tag.id === activeBadge"
              @click.native="changeBadge(tag.id)"
          />
        </swiper-slide>
      </swiper>
    </div>
  </content-wrapper>
</template>

<script>
import ContentWrapper from "@/components/Wrappers/ContentWrapper";
import SwiperRow from "@/components/Wrappers/SwiperRow";
import ObjectCard from "@/components/Parts/ObjectCard";
import Fact from "@/components/Parts/LittleCard";
import ShowMore from "@/components/Parts/ShowMore";
import Tag from "@/components/Parts/Tag";
import Event from "@/components/Parts/EventCard";
import Loader from "@/components/Parts/Loader";

export default {
  name: "CityScreen",
  components: {ContentWrapper, SwiperRow, ObjectCard, Fact, ShowMore, Tag, Event, Loader },

  data() {
    return {
      limit: 4,
      cityId: '',
      facts: null,
      places: [],

      activeBadge: 0,
      cities: [],
      types: [],

      page: 1,
      count: 10,
    };
  },

  computed: {
    loadingStatus() {
      return this.$store.getters.isLoadingPlaces;
    }
  },

  watch: {
    activeBadge(id) {
      if (id === 0) {
        this.getAllObjects();
      } else {
        this.getObjectsByCategory(id);
      }
    }
  },

  mounted() {
    api.getBadges().then(res => {
      this.badges = res.data;
    });

    api.getCity().then(res => {
      this.cityId = res.data.filter(city => city.is_default === true)[0].id;
      this.cities = res.data;

      this.getAllObjects();
    });

    api.getCategoriesTypes().then(res => {
      this.types = res.data;
    });

    this.scroll();
  },

  methods: {
    // fetchData() {
    //   api.getFacts(this.cityId).then(res => {
    //     this.facts = res.data;
    //   });
    // },

    getAllObjects() {
      api.getPlacesById('', this.cityId, this.count).then(res => {
        this.places = res.data;
      });
    },

    getObjectsByCategory(id) {
      api.getPlacesById(id, this.cityId).then(res => {
        this.places = res.data;
      });
    },

    goToObject(object) {
      this.$store.dispatch("setSelectedObject", object);
      this.$router.push({path: `/object/${object.id}`});
      this.$store.dispatch("fetchNearObjects", object.id);
    },

    goToObjects(type) {
      this.$router.push({path: `/objects/${this.cityId}/${type}`});
    },

    changeBadge(id) {
      this.activeBadge = id;
      this.page = 1;
    },

    scroll() {
      const elementContainer = document.querySelector('.content-wrapper');

      elementContainer.addEventListener('scroll', () => {
        let scrollHeight = elementContainer.scrollHeight;
        if (elementContainer.scrollTop + elementContainer.clientHeight >= scrollHeight) {
          this.page = this.page + 1;
          this.$store.commit('setIsLoadingPlaces', true);
          if (this.activeBadge === 0) {
            api.getPlacesById('', this.cityId, this.count, this.page).then(res => {
              this.places = [...this.places, ...res.data];
              this.$store.commit('setIsLoadingPlaces', false);
            });
          } else {
            api.getPlacesById(this.activeBadge, this.cityId, this.count, this.page).then(res => {
              this.places = [...this.places, ...res.data];
              this.$store.commit('setIsLoadingPlaces', false);
            });
          }
        }
      })

    },
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/utils/_variables.scss";

.city {
  padding-top: 56px;
  padding-bottom: 144px;

  & > div {
    position: relative;
    z-index: 3;
    margin-bottom: 56px;
  }

  .swiper-container {
    overflow: visible;
  }

  h1 {
    font-size: 50px;
    font-weight: bold;
    line-height: 1.12;
    letter-spacing: normal;
    color: rgba(#fff, 0.97);
    margin-top: 20px;
    margin-left: 32px;
    margin-bottom: 28px;
  }

  .swiper-slide {
    width: 488px;
    margin-right: 8px;
    margin-left: 32px;

    &:last-child {
      margin-right: 32px;
    }
  }

  .articles {
    .swiper-slide {
      width: 310px;
    }
  }

  .grid {
    margin: 0 32px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .event {
      width: 488px;

      margin-bottom: 84px;
    }
  }

  .events-plug {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 400px;
    font-size: 56px;
  }

  .swiper-width {
    position: absolute;
    bottom: 168px;
    left: 0;

    background: #242423;

    margin-bottom: 0px;

    z-index: 10;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 4px;
      background: rgba(255, 255, 255, 0.16);
    }

    .swiper-slide {
      width: auto;
      margin-right: 16px;

      &:not(:first-child) {
        margin-left: 0;
      }
    }
  }
}
</style>
